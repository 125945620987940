import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"
import sundialLogo from "../images/sundialLogo.svg"
import sundialAlpha from "../images/sundialAlpha.svg"
import Background from "../images/sundial.png"

const Page = class extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>ChatForge | Sundial</title>
          <meta name="description" content="Sundial is a conversational support solution"/>
        </Helmet>
        <body class="mainPage">
        <Header showMenu={false}/>
        <div class="content">
          <div class="info-page-intro">
            <div class="info-page-intro-holder">
              <div class="info-page-intro-details">
                <div class="info-page-intro-title has-alpha sundial-text">
                  <img class="info-page-intro-logo" src={sundialLogo} alt="Sundial Logo"/>
                  <div class="info-page-intro-text-container">
                    <img className="info-page-intro-alpha" src={sundialAlpha} alt="Alpha"/>
                    <span>Sundial</span>
                  </div>
                </div>
                <p>Sundial is our rostering solution that uses AI to quickly provide optimal staff schedules. Combined
                  with Chatforge, it provides a flexible, engaging solution to the challenge of staff planning. Please
                  contact us for a demonstration on how Sundial can help you save time by removing the frustration from
                  staff rostering.</p>
                <a href="/" target="_blank" rel="noopener noreferrer">
                  <button className="sundial-button longbutton">Start a chat with us to learn more about Sundial
                  </button>
                </a>
              </div>
            </div>
            <div class="info-page-intro-image" style={{ "backgroundImage": `url(${Background})` }}/>

          </div>
        </div>
        <Footer/>
        </body>
      </>
    )
  }
}

export default Page
